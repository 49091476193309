@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.qr-reader {
  .content {
    @include content();

    .reader {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
    }

  }
}