@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_page-auth.scss';

.signin {
  @include page-auth();

  .form .create {
    margin-top: $margin-md;
    p {
      @include h1();
      font-size: $medium;
      text-align: center;
      @media screen and (min-width: $xs-screen) {
        font-size: $h1;
      }
    }
    button {
      width: 100%;
    }
  }
}