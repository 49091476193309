@import "../../styles/_variables.scss";

.navbar {
  position: relative;
  z-index: 1;
  display: flex;
  background-color: $white;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  height: 90px;

  
  @media screen and (min-width: 1120px) {
    z-index: 5;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: $margin-sm;
    @media screen and (min-width: 1200px) {
      left: $margin-md;
    }
    transform: translateY(-50%);
    cursor: pointer;
    img {
      height: 60px;
    }
  }

  nav ul {
    position: relative;
    margin: 0;
    padding: 0;
    left: -35px;
    @media screen and (min-width: 1340px) {
      left: 0;
    }
    >li {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      height: 90px;
      padding: 0 $margin-xs;

      @media screen and (min-width: 1340px) {
        padding: 0 $margin-md;
      }
      a {
        display: block;
        letter-spacing: 1%;
        padding: $margin-xs $margin-sm;
        cursor: pointer;

        &.products {
          background-color: $third-color;

        }

        &.active {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            height: 3px;
            background-color: $secondary-color;
          }
        }

        .productsTotal {
          text-align: center;
          // vertical-align: baseline;
          display: inline-block;
          background-color: $secondary-color;
          color: $white;
          min-width: 30px;
          height: 30px;
          border-radius: 10px;
          padding: 5px;
        }
        
      }

      &.mobile {
        @media screen and (min-width: 1120px) {
          display: none;
        }
      }

    }

  }

  .actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    right: $margin-sm;
    @media screen and (min-width: 1200px) {
      right: $margin-md;
    }

    .burger {
      display: none;
      height: 44px;
      width: 80px;
    }

    .shoppingCart {
      display: none;
      // margin-right: $margin-md;
      border-right: 2px solid $grey;
      padding-right: 15px;
      @media screen and (min-width: 1120px) {
        display: block;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: $margin-xs;
          }
        }
      }

      .productsTotal {
        text-align: center;
        // vertical-align: baseline;
        display: inline-block;
        background-color: $secondary-color;
        color: $white;
        min-width: 30px;
        height: 30px;
        border-radius: 10px;
        padding: 5px;
      }

      a {
        color: $secondary-color;
      }

    }

    button {
      color: $text-color;
    }

    .notifications {
      display: none;
      color: $text-color-light;
      padding: 0 $margin-sm;
      cursor: pointer;
      @media screen and (min-width: 1120px) {
        display: block;
      }
      &.active {
        position: relative;
        color: $secondary-color;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #EC5E52;
          bottom: 5px;
          right: 10px;
        }
      }
    }

    .profile {
      display: flex;
      
      .picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 45px;
        border-radius: 5px;
        background-color: $secondary-color;
        margin-right: $margin-xs;
        cursor: pointer;
        p {
          margin: 0;
          color: $white;
          text-transform: uppercase;
        }

      }

      .dropdown {
        position: absolute;
        z-index: 4;
        background-color: $white;
        box-shadow: 0px 0px 26.438px 0px rgba(2, 53, 67, 0.15);
        border-radius: 10px;
        padding: $margin-sm $margin-sm;
        right: 0;
        top: 40px;
        transform: translateY($margin-sm);
        ul {
          padding: 0;
          li {
            list-style: none;
            margin: 3px 0;
            &:not(:last-child) .user {
              border-bottom: solid 1px $grey;
              margin-bottom: $margin-xs;
              padding-bottom: $margin-xs;
            }
            .user {
              display: flex;
              align-items: center;
              cursor: pointer;
              p {
                white-space: nowrap;
                margin: 0;
              }
            }
          }
        }
      }

    }

  }


  @media screen and (max-width: 1120px) {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    >div {
      border-bottom: solid 3px $grey;
    }

    .actions {
      .profile, button:not(.burger) {
        display: none;
      }

      .burger {
        display: block;
        padding: 0 $margin-md;
      }

    }

    &:not(.open) {
      nav {
        display: none;
      }
    }

    &.open {
      nav {
        position: fixed;
        top: 85px;
        bottom: 0;
        display: block;
        background-color: $white;
        overflow-y: auto;
        ul {
          display: block;
          left: 0px;
          li {
            display: block;
            height: auto;
            padding: $margin-sm;
            margin-bottom: $margin-sm;
          }
        }
      }
    }


    >div:first-child {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      padding: $margin-sm;
    }

    nav {
      flex: 1;
      width: 100%;

      .dropdownUsersTablet {
        padding: 0;
        margin: 0;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;

        .currentProfile {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: solid 1px $grey;
          // margin-bottom: $margin-sm;
          padding-bottom: $margin-sm;
          
          >div {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            p {
              margin: 0;
            }
          }
        }

        .picture {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          width: 45px;
          border-radius: 5px;
          background-color: $secondary-color;
          margin-right: $margin-xs;
          cursor: pointer;
          p {
            margin: 0;
            color: $white;
            text-transform: uppercase;
          }
  
        }

        ul {
          padding: 0;
          margin: $margin-sm 0 0 0;

          border-bottom: solid 1px $grey;
          margin-bottom: 0;
          padding-bottom: $margin-xs;

          >li {
            list-style: none;
            padding: 0 0 0 0;
            padding-left: 0 !important;

            .user {
              display: flex;
              align-items: center;
              cursor: pointer;

              p {
                white-space: nowrap;
                margin: 0;
              }
            }
          }
        }
      }

      li {
        position: relative;
        // padding-left: $margin-sl !important;
        // padding-right: $margin-md !important;

        a.linkNotifications {
          display: flex;
          align-items: center;
          height: 29px;
          .notifications {
            position: relative;
            top: 2px;
            margin-right: 5px;
            // &.active svg {
            //   fill: #EC5E52 !important;
            // }
            color: $text-color-light;
            &.active {
              position: relative;
              color: $secondary-color;
              &:after {
                content: '';
                position: absolute;
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: #EC5E52;
                bottom: 7px;
                right: 2px;
              }
            }
          }
        }

        a.active {
          color: $secondary-color;
          &:after {
            top: 0;
            bottom: 0;
            left: 0;
            height: 100% !important;
            width: 8px !important;
          }
        }
  
      }
      .logout {
        a {
          cursor: pointer;
          color: $secondary-color;
          svg {
            position: relative;
            top: 4px;
            margin-right: $margin-sm;
          }
        }
      }

    }

    .actions, .logo {
      position: relative;
      transform: translateY(0);
    }
    
    .actions {
      top: 0;
      left: 0;
    }

    .logo {
      top: 0;
      left: 0;
      right: 0;
    }

  }

}