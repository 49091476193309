@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.productsProvider {
  height: 100%;
  .content {
    @include content();
    display: flex;
    flex-direction: column;

    .containerList {
      border-radius: $radius;

      @media screen and (min-width: 1180px) {
        background-color: $white;
        overflow: hidden;

        .list {
          // height: 870px;
          overflow-y: auto;
        }
      }

      // .list {
      //   flex: 1;
      //   overflow-y: auto;
      // }

      .footer {
        padding: $margin-sm $margin-lg $margin-md $margin-lg;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

  }
}