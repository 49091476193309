@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.events {

  h2 {
    display: flex;
    cursor: pointer;
    @include remove-tap-highlight;
    button {
      position: relative;
      top: 4px;
      display: inline-block;
      vertical-align: middle;
      margin-left: auto;
      vertical-align: middle;
    }
  }

  .field {
    margin-bottom: $margin-sm;
  }
  
  .listEvents {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin-md;
    margin-bottom: $margin-md;

    @media screen and (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: $md-screen) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 1300px) {
      grid-template-columns: 1fr 1fr;
    }

    .seeMore {
      display: inline-block;
      margin: 0 auto;
      cursor: pointer;
      font-family: 'Museo-Bold';
      text-decoration: underline;
    }

    .event {
      position: relative;
      border: solid 1px $secondary-color;
      border-radius: $radius;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      height: 163px;
      &:hover {
        background-color: $bg-color;
      }
      &.selected {
        background-color: $secondary-color;
        .content {
          p,
          .speakers p {
            color: $white;
          }
        }
      }

      .content {
        display: flex;
        height: 100%;
        justify-content: space-between;

        >div {
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: $margin-md;

          >div {
            position: relative;
            width: 100%;
            // padding-right: 50px;

            @media screen and (min-width: $xs-screen) {
              padding-top: 10px;
            }

            .availableSeats {
              position: absolute;
              top: -14px;
              right: -12px;
              font-size: $small;
              color: $secondary-color;
            }
          }

          .type {
            font-family: "Museo-Bold";
            font-size: $small;
            margin-bottom: $margin-sm;
            padding-left: $margin-xs;
            border-left: solid 1px $secondary-color;
            @media screen and (min-width: $xs-screen) {
              margin-bottom: $margin-md;
            }
          }

          .name {
            font-family: "Museo-Bold";
            margin-bottom: $margin-xs;
          }

          .day {
            font-size: $small;
            margin-bottom: 0;
          }

          .speakers {
            p {
              display: inline-block;
              color: $secondary-color;
              font-size: $small;
              margin: 0 $margin-xs 0 0;
            }
          }
        }
        
        img {
          object-fit: cover;
          width: 163px;
          height: 163px;
        }

      }

    }
  }
}