@import '../../styles/mixins';
@import '../../styles/variables';

.usefulInformations {
  background-color: $white;

    .content {
      @include content();
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $margin-lg;
      padding: $margin-sm $margin-lg;

      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: $margin-sm;

        .card {
          width: 288px;
          height: 244px;
          border: solid 2px $border-grey;
          border-radius: $radius;
          padding: $margin-lg $margin-md 0;

          h2 {
            color: $black;
            text-transform: uppercase;
            text-decoration: underline;
          }

          h3 {
            @include h2();
            text-transform: uppercase;
            font-family: 'Museo-ExtaBold';
          }

          p {
            font-family: 'Museo';
            margin: 0 0 $margin-md;
          }

          .hr {
            background-color: $border-grey;
            border: none;
            height: 2px;
            
          }

          .zeroGaspi {
            padding: $margin-sm 0;
            display: flex;
            justify-content: center;
            text-align: start;
            gap: $margin-sm;

            img {
              object-fit: contain;
            }

            .textZeroGaspi {
              display: flex;
              flex-direction: column;
              align-items: end;
              margin: $margin-sm 0;

              h4 {
                font-family: 'Museo-ExtaBold';
                color: $secondary-color;
                font-size: $small;
                text-transform: uppercase;
              }

              p {
                font-size: $small;
                margin: 0;
              }
            }
          }
        }
      }

      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $margin-md;

        .spacePlan {
          width: 100%;
          max-width: 520px;
          img {
            width: 100%;
            object-fit: contain;
          }
        }

        button {
          text-transform: none;
          font-family: 'Museo';
        }
      }

      .locomotion {
        display: flex;
        flex-direction: column;
          justify-content: center;
          align-items: center;

        .locomotionItems {
          width: 100%;
          max-width: 884px;
          background-color: $secondary-color;
          border-radius: $radius;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: $margin-md;
          padding: $margin-lg;

          display: grid;
          grid-template-columns: auto;

          @media screen and (min-width: 550px) {
            grid-template-columns: auto auto;
          }

          @media screen and (min-width: 900px) {
            grid-template-columns: auto auto auto auto;
          }


        }

        .locomotionItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 190px;
          // height: 190px;
          // width: 100%;

          .imgContainer {
            width: 43px;
            margin: 0 0 15px;

            @media screen and (min-width: 550px) {
              height: 63px;
            }

            img {
              width: 100%;
              @media screen and (min-width: 550px) {
                object-fit: contain;
                object-position: center;
                height: 100%;
              }
  
            }
          }

          &:first-child .imgContainer,
          &:last-child .imgContainer {
            width: 70px;
            @media screen and (min-width: 550px) {
              height: 63px;
              width: 83px;
            }
          }

          h5 {
            color: $white;
            font-family: 'Museo-Bold';
            text-transform: uppercase;
            font-size: $medium;
            margin: 0 0 $margin-sm;
          }

          p {
            color: $white;
            font-family: 'MuseoSans';
            font-weight: lighter;
          }
        }
      }

      a {
        font-family: 'Museo-Bold';
        text-decoration: underline;
      }
    }
}