@import '../../styles/mixins';
@import '../../styles/variables';

.notifications {
    position: fixed;
    z-index: 2;
    max-width: 384px;
    width: 100%;
    background-color: $white;
    right: 0;
    bottom: 0;
    top: 83px;
    border-top: solid 2px $grey;
    overflow-y: auto;
      
    @media screen and (min-width: 1100px) {
        top: 89px;
        border-top: solid 3px $grey;
    }

    .close {
        position: absolute;
        cursor: pointer;
        right: $margin-sm;
        top: 15px;
        color: $secondary-color;
        @media screen and (min-width: 1100px) {
            display: none;
        }    
    }

    h1 {
        @include h1();
        margin: $margin-md 0;
        padding-left: $margin-md;
    }

    .notification {
        padding: $margin-sm $margin-md;
        line-height: $margin-md;
        cursor: pointer;

        span {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-left: $margin-sm;
            font-size: 12px;
            color: #A4B7BB;

            >div {
                display: inline;
                padding: unset;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: none;
                background-color: #EC5E52;
            }
        }

        p {
            padding: 0 $margin-sm;
            font-size: $small;
        }


        .hr {
            height: 1px;
            width: 92%;
            background-color: #A4B7BB;
            border: none;
        }
    }
    }
