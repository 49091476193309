@import '../../styles/mixins';
@import '../../styles/variables';

.anm {
  background-color: $white;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $margin-lg;
    text-align: center;

    .introduction {
      width: 100%;
      max-width: 800px;  
      text-align: left;

      @media screen and (max-width: 900px) {
        text-align: center;
      }

      .firstContainer {
        display: flex;
        justify-content: space-between; 

        @media screen and (max-width: 900px) {
          flex-direction: column;
          align-items: center;
          gap: $margin-md;
        }
      }

      .containerButtons {
        display: flex;
        flex-direction: column;
      }

      .text {
        width: 384px;
        text-align: start;

        @media screen and (max-width: 900px) {
          text-align: center;
        }

        @media screen and (max-width: $xs-screen) {
          width: 360px;
        }
      }

      p {
        font-family: 'Museo';
        margin: 0;
        line-height: 21px;
      }

      em {
        font-style: normal;
        font-family: 'Museo-ExtaBold';
      }

      .plan {
        width: 400px;
        height: 275px;
        // background-color: #F5F5F7;
        background-image: url('../../assets/images/exhibitions/plan_grand_palais.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        padding: $margin-sm;

        @media screen and (max-width: $xs-screen) {
          width: 360px;
        }
        
        p {
          font-family: 'Museo-Bold';
          transform: translateY(-80px);
        }

        a {
          align-self: flex-end;
        }

      }

      button {
        text-transform: unset;
        align-self:flex-start;
        margin-top: $margin-md;
      }

    }

    .presentation {
      
      h2 {
        color: $black;
        text-transform: uppercase;
        margin: 0;
      }

      a {
        font-family: 'Museo';
        text-decoration: underline;
        display: inline-block;
        margin: 0 0 $margin-md;
      }

      .cards {
        display: flex;
        justify-content: center;
        padding: 0 250px;
        flex-wrap: wrap;
        gap: $margin-lg;

        @media screen and (max-width: $lg-screen) {
          padding: 0 150px;
        }

        @media screen and (max-width: $md-screen) {
          padding: 0;
        }

        @media screen and (max-width: 738px) {
          gap: $margin-sm;
        }

        .topCard, .bottomCard {
          width: 305px;
          background-color: #F5F5F7;
          border-radius: 16px;
          margin: 0 0 $margin-md;

          // @media screen and (max-width: 738px) {
          //   img {
          //     display: none;
          //   }
          // }

          h3 {
            @include h2();
            margin: $margin-md 0 $margin-sm;
          }

          p {
            font-family: 'Museo';
            padding: 0 $margin-md;
          }

          li {
            list-style: none;
            font-family: 'Museo-Bold';
          }
        }
      }

      .topCard {
        height: 575px;

        // @media screen and (max-width: 738px) {
        //   height: calc(582px - 247px);
        // }
      }

      .bottomCard {
        height: 598px;

        // @media screen and (max-width: 738px) {
        //   height: calc(610px - 247px);
        // }
      }
    }
  }


}