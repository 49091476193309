@import '../../styles/variables';
@import '../../styles/mixins';

.modal {
  width: 100%;
  position: relative;
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.loading {
    >*:not(.loader) {
      opacity: 0.4;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: $margin-md;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $margin-md;
    margin-bottom: $margin-md;

    @media screen and (min-width: $xs-screen) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0;
    }

    .containerField {
      @include containerField();
      margin-bottom: 0;

      @media screen and (min-width: $xs-screen) {
       margin-bottom: $margin-md;
      }
    }
  
  }

  .containerButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}