@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.exhibitions {
  background-color: $white;
  
  .content {
    @include content();
    text-align: center;

    @media screen and (max-width: $md-screen) {
      padding: $margin-lg;
    }

    h1 {
      @include h1();
    }

    h2 {
      @include h2();
      color: $black;
    }

    .cards {
      @include content();
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $margin-lg;

      .card {
        width: 305px;
        height: 470px;
        background-color: #F5F5F7;
        border-radius: 16px;
      }

      img, button {
        margin-bottom: $margin-md;
      }

      h3 {
        @include h2();
        text-transform: uppercase;
        height: 72px;
        margin-bottom: $margin-sm;
      }

      em {
        font-style: normal;
        font-family: 'Museo-ExtaBold';
      }

      a {
        font-family: 'Museo';
        text-decoration: underline;
      }

      @media screen and (max-width: $md-screen) {
        padding: $margin-lg;
        flex-wrap: wrap;
      }
    }
  }

  .lastBloc {
    display: flex;
    justify-content: center;
    gap: $margin-lg;
    margin: 90px $margin-xl $margin-xl $margin-xl;

    @media screen and (max-width: $xs-screen) {
      margin: 90px $margin-lg $margin-xl $margin-lg;
      background-size: cover;
      background-position: right top;
    }

    @media screen and (max-width: $md-screen) {
      flex-wrap: wrap;
    }

    img {
      width: fit-content;
    }

    .badge, .formations {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-size: contain;
      background-repeat: no-repeat;
      width: 410px;
      height: 391px;
      border-radius: 10px;
      overflow: hidden;
      padding: $margin-xl;

      @media screen and (max-width: $md-screen) {
        padding: $margin-xl $margin-lg;
      }

      p {
        font-family: 'Museo';
      }
    }

    .badge {
      background-image: url('../../assets/images/exhibitions/bg-lightblue.png');
      align-items: center;
      justify-content: space-around;
      text-align: center;

      @media screen and (max-width: $xs-screen) {
        background-size: cover;
        background-position: right top;
      }

      h3 {
        @include h1();
        color: $white;
      }

      button {
        background-color: $white;
        color: $black;
        max-width: 270px;
      }

    }

    .formations {
      background-image: url('../../assets/images/exhibitions/bg-formations.png');
      width: 410px;
      height: 391px;

      @media screen and (max-width: $xs-screen) {
        background-size: cover;
        background-position: right top;
      }

      h3 {
        @include h1();
      }

      button {
        max-width: 190px;
      }
    }
  }
}