@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.orderValidated {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: $margin-lg;
    &.error {
      color: #EC5E52;
    }
  }
}

.detailProduct {
  display: flex;
  height: 100%;
  flex-direction: column;

  .content {
    flex: 1;
    overflow-y: auto;
    @include content();

    h1, h4 {
      font-family: 'MuseoSans-Bold';
      color: $text-color;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        span {
          display: inline-block;
          margin-left: $margin-sm;
        }
      }

    }

    .header {
      margin-bottom: $margin-md;
      margin-top: $margin-md;

      @media screen and (min-width: $sm-screen) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      h1 {
        margin-bottom: 0;
        span {
          font-size: $medium;
        }
      }

      p {
        display: inline-block;
        padding: $margin-xs $margin-sm;
        border-radius: 4px;
        background-color: $white;
        margin-left: 0;
        margin-bottom: 0;
        margin-top: $margin-sm;
        @media screen and (min-width: $sm-screen) {
          margin-left: $margin-sm;
          margin-top: 0;
        }
      }

      button {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $margin-sm;
        span {
          margin: 0 7px;
        }
      }

    }

    .detail {
      margin-bottom: $margin-sm;
      @media screen and (min-width: $md-screen) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      p {
        margin: 0 0 $margin-sm 0;
        @media screen and (min-width: $md-screen) {
          margin: 0;
        }
        font-family: 'Museo-light';
        span {
          font-family: 'Museo-Bold';
        }
      }

      .status {
        @media screen and (min-width: $md-screen) {
          margin-left: auto;
        }
        span {
          position: relative;
          display: inline-block;
          border: solid 1px var(--tonic);
          color: var(--tonic);
          padding: $margin-xs $margin-sm;
          border-radius: 4px;
          margin-left: $margin-sm;
          &:after {
            content: '';
            position: absolute;
            display: block;
            inset: 0;
            background-color: var(--tonic);
            opacity: 0.15;
          }
        }
      }

    }

    .contentShoppingCart {
      // background-color: $white;
      // border-radius: $radius;
    }

    .contentShoppingCart .detailShoppingCart {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      margin-top: $margin-md;
      margin-bottom: $margin-sm;
      // padding: $margin-md $margin-md 0 $margin-md;

      @media screen and (min-width: $md-screen) {
        flex: 1;
        
        margin-bottom: $margin-sm;
      }

      .shoppingCart {
        display: flex;
        align-items: center;
        color: $secondary-color;
        h2 {
          margin: 0 0 0 $margin-xs;
          span {
            color: $text-color;
          }
        }
      }

      .total {
        display: inline-block;
        background-color: $white;
        padding: $margin-xs $margin-sm;
        border-radius: 5px;

        p {
          font-family: 'Museo-light';
          margin-bottom: 0;
          span {
            font-family: 'Museo-Bold';
          }
        }

        &.save p span {
          color: $success;
        }

      }
    }
 
    .contentShoppingCart .list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $margin-md;
      margin-bottom: $margin-md;
    
      @media screen and (min-width: $md-screen) {
        grid-template-columns: 1fr 1fr;
      }
    
      li {
        list-style: none;
        .product {
          display: flex;
          background-color: $white;
          padding: $margin-md;
          border-radius: $radius;
          width: 100%;

          >div:first-child {
            flex: 1;
            margin-right: $margin-sm;
            overflow: hidden;
            .name {
              font-family: 'Museo-Bold';
            }
            p {
              font-family: 'Museo-light';
              margin: 0 0 $margin-xs 0;
            }

            .quantity {
              color: $text-color-light;
              margin-top: $margin-md;
              span {
                background-color: $bg-color;
                padding: $margin-xs;
                border-radius: 4px;
              }
            }

          }

          >div:last-child {
            p {
              font-family: 'Museo-Bold';
            }
          }

        }
      }
    }

  }

  >button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    padding: $margin-lg $margin-md;
    span {
      margin: 0 7px;
    }
  }

}