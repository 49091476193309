@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.collaborators {

  h2 {
    display: flex;
    cursor: pointer;
    @include remove-tap-highlight;
    button {
      position: relative;
      top: 4px;
      display: inline-block;
      vertical-align: middle;
      margin-left: auto;
      vertical-align: middle;
    }
  }
  
  .listCollaborators {
    margin-bottom: $margin-md;

    .collaborator {
      display: flex;
      align-items: center;
      margin-bottom: $margin-sm;
      padding: $margin-sm;
      border: solid 1px $border-grey;
      border-radius: $radius;

      .content {
        flex: 1;
        width: 100%;

        p {
          margin: 0 $margin-xs 0 0;
        }
  
        .presence {
          display: block;
          width: 100%;
          margin-top: $margin-xs;
          padding-top: $margin-xs;
          border-top: solid 1px $border-grey;

          p {
            font-size: $small;
            color: $text-color-light;
          }

        }
      }

      .delete {
        margin-left: $margin-sm;
      }

    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $margin-md;

    .containerField {
      @include containerField();
    }
  
  }

  button {
    text-transform: none;
  }
}