@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.formations {
  .content {
    @include content();

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;

      h1 {
        margin-bottom: $margin-sm;
      }
  
      .backward {
        @include backward();
      }

      .searchField {
        display: inline-block;
      }
  
    }

  }
}