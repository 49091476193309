@import '../../styles/variables';
@import '../../styles/mixins';

.modal {
  width: 100%;
  max-width: 500px;

  .stepHeader {
    display: flex;
    .icon {
      position: relative;
      top: 1px;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  h3 {
    font-family: 'MuseoSans-Bold';
    font-size: $regular;
    text-align: center;
    margin-bottom: $margin-md;
  }

  label {
    display: block;
    margin-bottom: $margin-sm;
  }


  .users {
    padding: 0;
    margin: $margin-sm 0;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: $margin-sm;
      }
    }
    .user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px $border-grey;
      border-radius: $radius;
      padding: $margin-sm;

      p {
        flex: 1;
        margin: 0;
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: $margin-xs;
      }

    }

    .user .checkbox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: $white;
      border: solid 3px $text-color;
      cursor: pointer;
      >svg {
        position: absolute;
        width: 20px;
        height: 20px;
        color: $text-color;
      }
    }

  }

  .day {
    margin-bottom: $margin-md;
    .name {
      font-family: 'MuseoSans-Bold';
      font-size: $regular;
      margin-bottom: $margin-sm;
    }

    .timeslots {
      button {
        font-family: 'MuseoSans';
        font-size: $small;
        border: none;
        color: $text-color-light;
        background-color: transparent;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #DBDBDB;
        padding: 10px;
        min-width: 60px;
        margin: 0 $margin-xs $margin-xs 0;
        &:disabled {
          opacity: 0.3;
          background: $grey;
        }
      }
      button:not(:disabled) {
        &.selected {
          background-color: $secondary-color;
          border-color: $secondary-color;
          color: $white;
          box-shadow: inset 0 0 0 1px $secondary-color;
        }
      }
    }

  }

  .containerActions {
    display: flex;
    justify-content: center;
  }

}