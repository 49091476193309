@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.waiting {
  flex: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    @include content();
    flex: 1;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}