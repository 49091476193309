@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.registerForm {

  h2 {
    @include h1 ();
    padding-bottom: $margin-md;
    border-bottom: solid 1px $border-grey;
    margin-bottom: $margin-md;
  }

  .row {

    grid-gap: $margin-md;

    @media screen and (min-width: $sm-screen) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .containerField {
      @include containerField();

      span {
        font-family: 'Museo-Bold', sans-serif;
        font-size: $regular;
        padding-top: 0;
      }

      p {
        display: inline-block;
        font-family: 'MuseoSans';
        font-size: $regular;
        width: 180%;
        margin-bottom: 0;

        @media screen and (max-width: $sm-screen) {
          width: 100%;
        }

        a {
          text-decoration: underline;
        }
      }

      .paragraphMailto {
        margin-bottom: $margin-sm;
      }
    }
  }

  .collaborators {
    margin: $margin-lg 0 $margin-xl 0;
  }

  .optin {
    position: relative;

    .checkbox {
      position: absolute;
    }

    p {
      text-indent: 27px;
      line-height: 22px;
    }
  }

  .containerBtn {
    button {
      width: 100%;
    }
  }

  .success {
    font-family: 'Museo-Bold';
    color: $secondary-color;
    margin-top: $margin-md;
  }

  .mention {
    padding-top: $margin-md;

    p {
      font-family: 'Museo-Bold';
      line-height: 26px;
      span {
        font-family: 'MuseoSans';
        color: $secondary-color;
        a {
          color: $secondary-color;
          text-decoration: underline;
        }
      }
    }

  }

}

