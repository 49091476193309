@import '../../styles/mixins';
@import '../../styles/variables';

.waitingPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  .banner {
    padding: $margin-sm;
    background: $gradient;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: $sm-screen) {
      padding: $margin-lg 0;
    }

    .logo {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;

      @media screen and (min-width: $md-screen) {
        display: block;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: -100px;
        top: -160px; 
        display: block;
        width: 383px;
        height: 375px;
        border-radius: 50%;
        background-color: $white;
      }

      img {
        position: relative;
        z-index: 1;
        margin: 35px;
        width: 125px;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    h1 {
      font-size: clamp(30px, 12vw, 60px);
      color: $white;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: center;
      margin-top: 30px;
    }

    img {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 30px auto;
    }
  }

  .mainContent {
    padding: $margin-lg;
    text-align: center;
    flex-grow: 1;

    @media screen and (min-width: $sm-screen) {
      padding: $margin-xl;
    }

    h1, button, div {
      margin: 0 0 $margin-lg;

      @media screen and (min-width: $sm-screen) {
        margin: 0 0 $margin-xl;
      }
    }

    h1 {
      font-size: 26px;
    }

    button {
      cursor: default;
    }

    p {
      font-size: 22px;
    }

    em {
      font-style: normal;
      font-family: 'MuseoSans-Bold';
      color: $secondary-color;
    }
  }

  footer {
    padding: $margin-sm;
    background: $gradient;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: $margin-md;

    @media screen and (min-width: $sm-screen) {
      padding: $margin-lg;
    }

    span {
      color: $white;
      font-family: 'MuseoSans';
      text-align: center;
    }

    @media screen and (min-width: $lg-screen) {
      flex-direction: row;
      padding: $margin-lg;
      justify-content: flex-start;
      align-items: end;
      gap: $margin-xl*2;

      img {
        margin: 0 $margin-xl;
      }
    }
  }
}