@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-right: 2px;
        @media screen and (min-width: $sm-screen) {
          margin-right: $margin-xs;
        }
      }
    }
  }

  >.square:first-child {
    @media screen and (min-width: $sm-screen) {
      margin-right: $margin-xs;
    }
  }
  >.square:last-child {
    @media screen and (min-width: $sm-screen) {
      margin-left: $margin-xs;
    }
  }

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    background-color: $bg-color;
    @include remove-highlight();
    @include remove-tap-highlight();

    @media screen and (min-width: $sm-screen) {
      height: 34px;
      width: 34px;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    span {
      position: relative;
      top: 1px;
    }


    &.active {
      border: solid 1px $secondary-color;
      background-color: $secondary-color;
      color: $white;
    }
  }

}
