@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.detailProduct {

  .content {
    @include content();

    .header {
      margin-bottom: $margin-md;
      margin-top: $margin-md;
      @media screen and (min-width: $md-screen) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
      }

      span {
        position: relative;
        top: -6px;
        background-color: $white;
        font-size: $regular;
        padding: $margin-xs;
        border-radius: 4px;
        margin-left: $margin-sm;
      }
    }

    h1, h4 {
      font-family: 'MuseoSans-Bold';
      color: $text-color;
    }    


    .detail {
      display: grid;
      gap: $margin-md;
      grid-template-columns: 1fr;

      @media screen and (min-width: $md-screen) {
        grid-template-columns: 1fr 1fr;
      }


      section {
        h4 {
          margin-bottom: $margin-sm;
        }

        >div {
          background-color: $white;
          border-radius: $radius;
          padding: $margin-sm;
          @media screen and (min-width: $md-screen) {
            padding: $margin-md;
          }


          &.pricings {
            position: relative;
            .recommendedPrice {
              position: relative;
              display: inline-block;
              top: 0;
              right: 0;
              background-color: $bg-color;
              font-size: $regular;
              padding: $margin-xs;
              border-radius: 4px;

              @media screen and (min-width: $sm-screen) {
                position: absolute;
                top: $margin-sm;
                right: $margin-sm;
                margin-left: $margin-sm;
              }

              p {
                margin: 0;
              }
            }

            .discount {
              span{
                font-family: 'MuseoSans-Bold';
                background-color: rgba(#EC5E52, 0.5);
                margin: 0;
                padding: 3px 5px;
                border-radius: 3px;
              }
            }

          }


          p {
            span {
              font-family: 'MuseoSans-Bold';
            }
          }

        }
      }
    }

  }
}