@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.products {

  >:first-child {
    width: 100%;
  }

  .content {
    @include content();

    .header {
      margin-bottom: $margin-md;

      h1 {
        margin-bottom: $margin-sm;
      }

      .filters {
        display: grid;
        grid-template-columns: repeat(2, 1fr);


        @media screen and (min-width: 800px) {
          display: flex;
          grid-template-columns: inherit;
          grid-template-rows: inherit;
        }

        .containerButtonFilter { 
          grid-area: 1 / 1 / 2 / 2;
          margin-bottom: $margin-xs;
          @media screen and (min-width: 800px) {
            grid-area: inherit;
            margin-bottom: 0;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: inherit;
            .icon {
              position: relative;
              top:2px;
              color: $secondary-color;
              margin-left: 5px;
            }
          }

        }

        .search { 
          display: flex;
          justify-content: flex-end;
          grid-area: 1 / 2 / 2 / 3;
          margin-bottom: $margin-xs;
          @media screen and (min-width: 800px) {
            grid-area: inherit;
            margin-bottom: 0;
          }
        }
        
        .selectFilters {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-area: 2 / 1 / 3 / 3;
          max-width: 400px;

          @media screen and (min-width: 800px) {
            display: flex;
            grid-area: inherit;
            flex-direction: row;
            max-width: inherit;
          }
        }

        .field {
          margin-right: $margin-md;
          @media screen and (min-width: 800px) {
            margin-right: $margin-lg;  
          }
        }
      }
    }

    .containerList {
      border-radius: $radius;

      @media screen and (min-width: 1180px) {
        background-color: $white;
        overflow: hidden;

        .list {
          height: 870px;
          overflow-y: auto;
        }
      }



      .footer {
        padding: $margin-sm 0 $margin-md 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (min-width: 1180px) {
          padding: $margin-sm $margin-lg $margin-md $margin-lg;
        }
      }
    }

  }
}