@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.modalCollaborator {
  h1 {
    text-align: center;
    margin-bottom: $margin-lg;
  }

}

.onlyOnTablet {
  display: block;
  @media screen and (min-width: $md-screen) {
    display: none;
  }
}

.onlyOnDesktop {
  display: none;
  @media screen and (min-width: $md-screen) {
    display: block;
  }
}

.account {

  button {
    display: flex;
    align-items: center;
    span + span {
      margin-left: $margin-sm;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    padding: $margin-sm;
    margin: 0 auto;
  
    @media screen and (min-width: $xs-screen) {
      padding: $margin-md $margin-md 0 $margin-md;
    }
  
    @media screen and (min-width: $sm-screen) {
      padding: $margin-lg $margin-lg 0 $margin-lg;
    }

    @media screen and (min-width: $md-screen) {
      flex-direction: row;
      align-items: center;
      button {
        margin: 0 0 0 auto;
        text-align: center;
      }
    }

    button {
      width: 350px;
    }

    .loader {
      position: relative;
      top: 3px;
      margin-left: $margin-sm;
    }

    .name {
      display: flex;
      margin-bottom: $margin-md;
      @media screen and (min-width: $md-screen) {
        margin-bottom: 0;
      }

      h1, p {
        margin-bottom: 0;
      }
  
      p {
        font-family: 'Museo-Light';
        background-color: $white;
        border-radius: 3px;
        padding: 5px;
        margin-left: $margin-sm;
      }
    }



  }

  .content {
    @include content();
    @media screen and (min-width: $md-screen) {
      display: grid;
      grid-template-columns: 1fr max(350px);
      gap: $margin-md;
    }
    
    .form {
      .headerBlock {
        display: flex;
        border-bottom: solid 1px $grey;
        margin-bottom: $margin-md;
        h2 {
          font-family: 'MuseoSans-Bold';
          margin-bottom: $margin-md;
          color: $text-color;
        }
      }
  
      .row {
        grid-gap: $margin-md;
    
        @media screen and (min-width: $sm-screen) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
    
        .containerField {
          @include containerField();
        }
      
      }
  
    }

    .logout {
      color: $secondary-color;
      svg {
        position: relative;
        top: 2px;
      }
    }

    section.onlyOnTablet {
      margin-top: $margin-lg;
      padding-top: $margin-md;
      border-top: solid 1px $grey;
      .logout {
        margin: 0 auto;
      }
    }

    aside {
      .badges {
        .addUser {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: $margin-md;
          padding: $margin-md $margin-sm;
          width: 100%;
  
  
          >div {
            display: flex;
            align-items: center;
          }
  
          span {
            white-space: nowrap;
          }
          .loader {
            position: relative;
            top: 2px;
            margin-right: $margin-sm;
          }
        }
  
        .users {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            &:not(:last-child) {
              border-bottom: solid 1px $grey;
              margin-bottom: $margin-xs;
              padding-bottom: $margin-xs
            }
            .user {
              display: flex;
              align-items: center;
              cursor: pointer;
  
              button {
                position: relative;
                top: 1px;
                margin-right: $margin-xs;
              }
  
              p {
                font-family: 'MuseoSans-Bold';
                margin: 0;
              }
            }
          }
        }
      }
  
      .formations {
        margin-bottom: $margin-md;
        .formation {
          border: solid 1px $secondary-color;
          border-radius: $radius;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          height: 163px;
          background-color: $white;
          &:not(:last-child) {
            margin-bottom: $margin-md;
          }
          .formationContent {
            display: flex;
            height: 100%;
            justify-content: space-between;
    
            >div {
              position: relative;
              display: flex;
              height: 100%;
              flex-direction: column;
              justify-content: space-between;
              padding: $margin-md;
    
              .type {
                font-family: "Museo-Bold";
                font-size: $small;
                margin-bottom: $margin-md;
                padding-left: $margin-xs;
                border-left: solid 1px $secondary-color;
              }
    
              .name {
                font-family: "Museo-Bold";
                margin-bottom: $margin-xs;
              }
    
              .day {
                font-size: $small;
                margin-bottom: 0;
              }
    
              .speakers {
                p {
                  display: inline-block;
                  color: $secondary-color;
                  font-size: $small;
                  margin: 0 $margin-xs 0 0;
                }
              }
            }
            
            img {
              object-fit: cover;
              width: 163px;
              height: 163px;
            }
    
          }
    
        }
      }
    } 

  }

}