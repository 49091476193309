@import '../../../styles/_variables.scss';

.modalActions {
  background-color: $white;
  border-radius: $radius;
  box-shadow: 0px 0px 26.438px 0px rgba(2, 53, 67, 0.15);
  p {
    padding: $margin-sm $margin-md;
    margin: 0;
    cursor: pointer;
    &:last-child {
      color: $warn;
      border-top: solid 1px $grey;
    }
  }
}

.productsTable {
  padding: 0 $margin-lg 0 $margin-lg;
  background-color: $white;
  border-radius: $radius;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;

    thead {
      position: relative;
      position: sticky;
      top: 0;
      background-color: white;
      &:after {
        content: '';
        position: absolute;
        right: calc(-1 * $margin-lg);
        left: calc(-1 * $margin-lg);
        bottom: 0;
        border-bottom: solid 1px $border-grey;
      }


      th {
        position: relative;
        padding: $margin-md 0 $margin-md 0;
        font-family: 'Museo-light';
        color: $text-color-light;
        text-align: left;
        cursor: pointer;

        &:nth-child(3){
          width: 130px;
        }

        &:nth-child(4){
          width: 110px;
        }

        &:nth-child(5){
          width: 130px;
        }

        &:nth-child(6){
          width: 110px;
        }

        .sortIcons {
          position: relative;
          top: -6px;
          display: inline-flex;
          flex-direction: column;
          // opacity: 0.3;
          margin-left: $margin-xs;
          height: 20px;

          span:not(.active) {
            opacity: 0.3;
          }

          >span:first-child {
            transform: translateY(5px);
          }
          >span:nth-child(2) {
            transform: translateY(-5px);
          }
        }

      }
    }

    tbody {
      tr {
        position: relative;
        cursor: pointer;
        &:not(:last-child) td {
          border-bottom: solid 1px $border-grey;
        }

      }
      
      td {
        padding: $margin-md $margin-xs $margin-md 0;
    
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.quantity span {
          display: inline-block;
          background-color: $bg-color;
          font-size: $regular;
          padding: $margin-xs;
          border-radius: 4px;
        }


        &.discount p {
          font-family: 'MuseoSans-Bold';
          span {
            color: $success;
          }
        }

        &.totalPrice p {
          font-family: 'MuseoSans-Bold';
        }

      }

      td.actions {
        position: absolute;
        right: -60px;
        top: 50%;
        transform: translate(0, -50%);
        // background-color: red;
        padding: 20px 3px 20px 3px;
        border-bottom-color: transparent !important;
        color: #023543;
        .position {
          position: relative;
          top: -40px;
        }
      }

    }

  }
}

.listTablet {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $margin-md;
  margin-bottom: $margin-md;

  @media screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  li {
    list-style: none;
    .product {
      background-color: $white;
      padding: $margin-md;
      border-radius: $radius;
      .name {
        font-family: 'Museo-Bold';
      }
      p {
        font-family: 'Museo-light';
        margin: 0 0 $margin-xs 0;
      }

      .ref {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: $margin-md;
        p {
          color: $text-color-light;
          margin-bottom: 0;
          span {
            font-family: 'Museo-Bold';
            color: $text-color;
          }
        }
      }


    }
  }
}