@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.orderValidated {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: $margin-lg;
    &.error {
      color: #EC5E52;
    }
  }
}

.detailProduct {
  display: flex;
  height: 100%;
  flex-direction: column;

  .content {
    flex: 1;
    overflow-y: auto;
    @include content();

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        span {
          display: inline-block;
          margin-left: $margin-sm;
        }
      }
      .status {
        span {
          position: relative;
          display: inline-block;
          border: solid 1px var(--tonic);
          color: var(--tonic);
          padding: $margin-xs $margin-sm;
          border-radius: 4px;
          &:after {
            content: '';
            position: absolute;
            display: block;
            inset: 0;
            background-color: var(--tonic);
            opacity: 0.15;
          }
        }
      }

    }

    .header {
      margin-bottom: $margin-md;
      margin-top: $margin-md;

      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
        }


        .shoppingCart {
          display: flex;
          align-items: center;
          color: $secondary-color;
          h2 {
            margin: 0 0 0 $margin-xs;
          }
        }

        .total {
          display: inline-block;
          background-color: $white;
          padding: $margin-xs $margin-sm;
          border-radius: 5px;

          p {
            font-family: 'Museo-light';
            span {
              font-family: 'Museo-Bold';
            }
          }

          &.save p span {
            color: $success;
          }

        }
      }

    }

    h1, h4 {
      font-family: 'MuseoSans-Bold';
      color: $text-color;
    }

    .list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $margin-md;
      margin-bottom: $margin-md;
    
      @media screen and (min-width: $sm-screen) {
        grid-template-columns: 1fr 1fr;
      }
    
      li {
        list-style: none;
        .product {
          display: flex;
          flex-wrap: wrap;
          background-color: $white;
          padding: $margin-md;
          border-radius: $radius;
          width: 100%;

          >div:first-child {
            flex: 1;
            margin-right: $margin-sm;
            overflow: hidden;
            .name {
              font-family: 'Museo-Bold';
            }
            p {
              font-family: 'Museo-light';
              margin: 0 0 $margin-xs 0;
            }

            .quantity {
              color: $text-color-light;
              margin-top: $margin-md;
              span {
                background-color: $bg-color;
                padding: $margin-xs;
                border-radius: 4px;
              }
            }

          }

          >div:last-child {
            p {
              font-family: 'Museo-Bold';
            }
          }

          .actions {
            display: flex;
            flex: 1;
            min-width: 100%;
            justify-content: space-between;
            margin-top: $margin-md;

            button {
              text-transform: none;
              text-decoration: underline;
              color: $secondary-color;
            }

            .delete {
              color: $warn;
            }

          }

        }
      }
    }

  }

  >button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    padding: $margin-lg $margin-md;
    span {
      margin: 0 7px;
    }
  }

}