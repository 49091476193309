@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.container {
  display: flex;
  align-items: flex-start;

  &.white {
    button {
      background-color: $white;
    }
  }

  &:not(.white) {
    button {
      background-color: $bg-color;
    }
  }

  >div {
    display: flex;
    button {
      border: 1px solid $bg-color;
      padding: $margin-xs $margin-sm;
      background-color: $bg-color;
      color: $text-color;
      border-radius: 5px;
      height: 40px;
      width: 40px;
      &:disabled {
        opacity: 0.5;
      }
    }
  
    .input {
      display: inline-block;
      border: 1px solid $secondary-color;
      font-family: 'Museo-Bold';
      font-size: $regular;
      padding: $margin-xs;
      height: 40px;
      text-align: center;
      border-radius: 5px;
      max-width: 55px;
      appearance: textfield;
      -moz-appearance: textfield;
      margin: 0 $margin-xs;
      &:disabled {
        opacity: 0.3;
      }
      +button:disabled {
        opacity: 0.5;
      }
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .validation {
    background-color: transparent;
    color: $white;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 20px;
    padding: 5px 0 0 2px;
    margin-left: $margin-sm;
    color: $secondary-color;

    @include remove-tap-highlight();

    &:disabled {
      opacity: 0.3;
    }

    &.active {
      background-color: $secondary-color;
      color: $white;
    }
  }

}