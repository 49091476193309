@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.formation {
  .content {
    @include content();

    .loader {
      position: absolute;
      margin-bottom: $margin-md;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }


    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;
      flex: 1;
      width: 100%;

      .unsubscribe {
        background-color: $bg-color;
        color: $warn;
        border: solid 1px $warn;
      }

    }

    .information {
      display: flex;
      flex-direction: column-reverse;

      @media screen and (min-width: $md-screen) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }


      >.detail {
        flex: 0.6;
        @media screen and (min-width: $md-screen) {
          width: 810px;
        }

        .formationName {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          margin-bottom: $margin-xs;

          h1 {
            margin: 0 0 $margin-sm 0;
            min-width: 100%;
            flex: 1;
            @media screen and (min-width: $sm-screen) {
              flex: auto;
              min-width: auto;
            }
            span {
              font-size: $medium;
            }
          }

          >span {
            position: relative;
            display: inline-block;
            font-family: 'MuseoSans-Bold';
            font-size: $regular;
            color: $text-color;
            background-color: $white;
            border: solid 1px $secondary-color;
            border-radius: 10px;
            padding: $margin-xs $margin-sm;
            margin: 0 $margin-sm $margin-md 0;
            @media screen and (min-width: $sm-screen) {
              margin: 0 $margin-sm 0 0;
            }
          }
        }

        h3 {
          font-family: 'MuseoSans';
          font-size: $regular;
        }


        label {
          display: block;
          margin-bottom: $margin-md;
          color: $text-color-light;
        }

        section {
          margin-bottom: $margin-lg;
          @media screen and (min-width: $md-screen) {
            margin-bottom: $margin-xl;
          }
        }


        section .speakers {

          @media screen and (min-width: 620px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $margin-md;
            // flex-wrap: wrap;
          }

          .speaker {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 10px;
            padding: $margin-md;
            // width: 300px;
            // min-width: 300px;
            margin-bottom: $margin-sm;

            @media screen and (min-width: 620px) {
              margin-bottom: 0;
            }

            .picture {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 46px;
              height: 46px;
              min-width: 46px;
              min-height: 46px;
              border-radius: 50%;
              background-color: $secondary-color;
              margin-right: $margin-sm;
              overflow: hidden;
              p {
                font-family: 'MuseoSans-Bold';
                font-size: $regular;
                color: $white;
                margin: 0;
              }

              img {
                width: 46px;
                height: 46px;
                min-width: 46px;
                min-height: 46px;
                object-fit: cover;
              }

            }

            .detail {
              flex: 1;
              .name {
                font-family: 'MuseoSans-Bold';
                margin-bottom: $margin-xs;
              }
  
              p {
                margin-bottom: 0;
              }
            }



          }
        }

        section {

          .headerTimeslots {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: $margin-md;

            label {
              margin-bottom: 0;
            }

            button {
              background-color: $white;
              border: 1px solid var(--gris, #A4B7BB);
              padding: 15px;
              width: 50px;
              height: 50px;
              img {
                width: 20px;
                height: 20px;
                object-fit: contain;
              }

            }
          }

          .timeslots {
            .name {
              font-family: 'MuseoSans-Bold';
              font-size: $regular;
              margin-bottom: $margin-sm;
            }
          }

          .timeslots:not(.animator) .day {
            margin-bottom: $margin-md;

            .timeslots {
              button {
                font-family: 'MuseoSans';
                font-size: $small;
                border: none;
                color: $text-color-light;
                background-color: $white;
                border-color: $white;
                border-radius: 4px;
                // box-shadow: inset 0 0 0 1px #DBDBDB;
                padding: 15px;
                min-width: 60px;
                margin: 0 $margin-sm $margin-sm 0;
                cursor: text !important;
              }
              button.selected {
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: $white;
                box-shadow: inset 0 0 0 1px $secondary-color;
              }
            }
        
          }

          .timeslots.animator {
            display: grid;
            grid-template-columns: 1fr;
            gap: $margin-md;

            @media screen and (min-width: $md-screen) {
              grid-template-columns: 1fr 1fr;
            }
            
            .day {
              .timeslots>div {
                display: flex;
                justify-content: space-between;
                background: $white;
                border-radius: 5px;
                padding: $margin-sm $margin-sm;
                margin-bottom: $margin-xs;
                cursor: pointer;
                p {
                  font-family: 'Museo-light';
                  margin: 0;
                }
              }
            }
          }
        }

      }


      .containerImage {
        position: relative;
        flex: 0.4;
        height: 500px;
        min-width: 350px;
        margin-bottom: $margin-lg;

        @media screen and (min-width: $md-screen) {
          max-width: inherit;
          margin-left: $margin-lg;
          margin-bottom: 0;
        }

        >div {
          position: relative;
          width: 100%;
          padding-top: 100%;
          background-color: $white;
          border-radius: $radius;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }


      }
      

    }


    

  }
}