@import '../../../styles/mixins';
@import '../../../styles/variables';

.offers {
  background-color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $margin-lg;
  padding: $margin-lg;
  padding-bottom: $margin-sm;
  margin-bottom: -40px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;

    .mainContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $margin-sm;
      position: relative;
      bottom: $margin-lg;
    }

    img {
      width: fit-content;
      object-fit: contain;
    }

    .objetVectoriel1 {
      align-self: baseline;
    }

    p {
      text-align: center;
      color: $white;
      font-family: 'MuseoSans';
    }

    a {
      width: calc(100% - $margin-lg);
    }

    button {
      color: $black;
      font-size: $regular;
      text-transform: none;
      white-space: nowrap;
      width: 100%;
    }
    
    .yellowButton {
      background-color: $third-color;
    }

    .whiteButton {
      background-color: $white;
    }
  }

  .secondContent {
    position: relative;

    @media screen and (max-width: 900px) {
      img {
        max-width: 400px;
        width: 100%;
      }
    }

    @media screen and (max-width: $xs-screen) {
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .objetVectoriel2 {
      width: fit-content;
      position: absolute;
      bottom: 45px;
      right: 0px;

      @media screen and (max-width: $xs-screen) {
        bottom: 35px;
        right: -10px;
      }
  
      // @media screen and (max-width: 700px) {
      //   object-fit: contain;
      //   bottom: -108px;
      //   transform: rotate(6deg);
      // }
    }
  }

}