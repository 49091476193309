@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.register {
  // position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  min-height: 100%;
  overflow-y: auto;
  background: $gradient;

  @media screen and (min-width: $md-screen) {
    position: relative;
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  .infos {
    width: 100%;
    // scrollbar-gutter: stable both-edges;


    @media screen and (min-width: $md-screen) {
      width: 40%;
      // min-height: 100%;
      // overflow-y: auto;
    }

    .header {
      background-color: $white;
      display: flex;
      font-size: 0;
      
      .logo {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      padding: $margin-md;
      @media screen and (min-width: $lg-screen) {
        padding: $margin-xl;
      }

      >img {
        display: block;
        width: 100%;
        max-width: 250px;
        margin: $margin-md auto $margin-xl auto;
        @media screen and (min-width: $sm-screen) {
          margin: $margin-md 0 $margin-xl 0;
        }

        @media screen and (min-width: $md-screen) {
          margin: 0 0 $margin-xl 0;
        }
      }

      h2, p, a {
        color: $white;
      }

      h2 {
        font-size: $h1;
        border-bottom: solid 1px $border-grey;
        padding: 0 0 $margin-md 0;
        &:nth-child(4) {
          display: none;
          @media screen and (min-width: $md-screen) {
            display: block;
          }
        }

        + p {
          margin-bottom: $margin-xl;
        }
      }

      p, a {
        font-size: $small;
        line-height: 30px;
        span {
          font-family: 'MuseoSans-Bold';
        }
      }

      section {
        display: none;
        @media screen and (min-width: $md-screen) {
          display: block;
        }
      }

    

      section:not(:last-child) {
        border-bottom: solid 1px $border-grey;
        padding: 0 0 $margin-md 0;
        margin-bottom: $margin-md;
      }

      p > a {
        text-decoration: underline;
      }

    }

  }

  .form {
    width: 100%;
    background-color: $white;
    padding: $margin-md;

    // scrollbar-gutter: stable both-edges;

    @media screen and (min-width: $xs-screen) {
      padding: $margin-lg;
      // overflow-y: auto;
      // min-height: 100%;
    }

    @media screen and (min-width: $md-screen) {
      width: 60%;
      padding: $margin-xl;
      // overflow-y: auto;
      // min-height: 100%;
    }


  }

}