@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.conference {
  .content {
    @include content();

    .loader {
      position: absolute;
      margin-bottom: $margin-md;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }


    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;
      flex: 1;
      width: 100%;

    }

    .information {
      display: flex;
      flex-direction: column-reverse;

      @media screen and (min-width: $md-screen) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }


      >.detail {
        flex: 0.6;

        .formationName {
          display: flex;
          margin-bottom: $margin-xs;

          h1 {
            margin: 0;
            span {
              font-size: $medium;
            }
          }

          >span {
            position: relative;
            display: inline-block;
            font-family: 'MuseoSans-Bold';
            font-size: $regular;
            color: $text-color;
            background-color: $white;
            border: solid 1px $secondary-color;
            border-radius: 10px;
            padding: $margin-xs $margin-sm;
            margin-left: $margin-sm;
          }
        }

        h3 {
          font-family: 'MuseoSans';
          font-size: $regular;
        }


        label {
          display: block;
          margin-bottom: $margin-md;
          color: $text-color-light;
        }

        section {
          margin-bottom: $margin-lg;

          &:first-child {
            margin-bottom: $margin-md;
          }

          @media screen and (min-width: $md-screen) {
            margin-bottom: $margin-xl;
            &:first-child {
              margin-bottom: $margin-md;
            }
          }
        }

        section {
          .timeslot {
            background-color: $white;
            display: inline-flex;
            padding: $margin-md $margin-sm;
            border-radius: $radius;
            p {
              margin: 0 $margin-sm 0 0;
              &:first-child {
                font-family: 'Museo-Bold';
              }
            }
          }
        }


        section .speakers {

          @media screen and (min-width: 620px) {
            display: flex;
          }

          .speaker {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 10px;
            padding: $margin-md;
            width: 300px;
            min-width: 300px;
            margin-right: 0;
            margin-bottom: $margin-sm;

            @media screen and (min-width: 620px) {
              margin-bottom: 0;
              margin-right: $margin-md;
            }

            .picture {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 46px;
              height: 46px;
              min-width: 46px;
              min-height: 46px;
              border-radius: 50%;
              background-color: $secondary-color;
              margin-right: $margin-sm;
              overflow: hidden;
              p {
                font-family: 'MuseoSans-Bold';
                font-size: $regular;
                color: $white;
                margin: 0;
              }

              img {
                width: 46px;
                height: 46px;
                min-width: 46px;
                min-height: 46px;
                object-fit: cover;
              }

            }

            .detail {
              flex: 1;
              .name {
                font-family: 'MuseoSans-Bold';
                margin-bottom: $margin-xs;
              }
  
              p {
                margin-bottom: 0;
              }
            }



          }
        }

      }


      .containerImage {
        position: relative;
        flex: 0.4;
        height: 500px;
        max-width: 350px;
        margin-bottom: $margin-lg;

        @media screen and (min-width: $md-screen) {
          max-width: inherit;
          margin-left: $margin-lg;
          margin-bottom: 0;
        }

        >div {
          position: relative;
          width: 100%;
          padding-top: 100%;
          background-color: $white;
          border-radius: $radius;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }


      }
      

    }


    

  }
}