@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.row {

  @media screen and (min-width: $sm-screen) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $margin-md;
  }

  .containerField {
    @include containerField();
  }

}