@import '../../../styles/mixins';
@import '../../../styles/variables';

.content {
  background-color: #DEF2FB;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $margin-md;
  padding: $margin-xl;

  @media screen and (max-width: $sm-screen) {
    padding: $margin-xl $margin-md $margin-md;
    align-items: normal;
  }

  .planContainer {
    text-align: center;

    @media screen and (max-width: $xs-screen) {
      text-align: start;
    }

    h2 {
      color: $black;
      margin: $margin-xs;
    }
  
    a {
      text-decoration: underline;
      font-family: 'Museo';
      margin-left: $margin-xs;
    }
  }

  .items {
    max-width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: $margin-xl;

    @media screen and (max-width: 1440px) {
      // justify-content: space-around;
      flex-wrap: wrap;
    }

    @media screen and (max-width: $md-screen) {
      gap: $margin-md;
    }

    // @media screen and (max-width: $sm-screen) {
    //   gap: $margin-sm;
    // }

    @media screen and (max-width: $xs-screen) {
      flex-direction: column;
      gap: $margin-lg;
    }
  
    .item {
      width: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: $margin-md;

      @media screen and (max-width: $xs-screen) {
        width: 100%; 
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $margin-md;

        img {
          width: 116px;
          margin-bottom: 0;
          object-fit: contain;
        }
      }

      .itemTexts {
        display: flex;
        flex-direction: column;
        gap: $margin-md;

        @media screen and (max-width: $xs-screen) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: start;
          gap: $margin-sm;

          p {
            margin-bottom: 0;
            font-size: $small;
          }

          h3 {
            font-size: $regular;
          }
        }
      }

      h3, p {
        font-family: 'Museo';
      }

      h3 {
        @include h2();
      }
    }
  }

}