@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.editFormation {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .backward {
      @include backward();
    }

    .containerActions {
      text-align: right;
    }

  }
  .content {
    @include content();
    
    
    >.containerActions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 430px);

      .delete {
        color: $warn;
        svg {
          position: relative;
          top: 2px;
          margin-right: $margin-xs;
        }
      }

      .error {
        width: 100%;
      }
    }
  }
}