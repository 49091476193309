@import '../../styles/mixins';
@import '../../styles/variables';

.footer {
  width: 100%;
  background: $gradient;

  .questions {
    @include content();
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $margin-lg;

    @media screen and (min-width: $xs-screen) and (max-width: $lg-screen) {
      padding: $margin-xl $margin-sm;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
    }

    @media screen and (min-width: $lg-screen) {
      padding: $margin-xl $margin-sm;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    div {
      text-align: center;
      @media screen and (min-width: $xs-screen) {
        text-align: start;
      }
    }

    .question {
      font-family: 'Museo-Bold';
      color: $white;
      font-size: $medium;
      @media screen and (min-width: $xs-screen) {
        font-size: $h1;
      }
    }

    p {
      color: $white;
      font-size: $small;
      @media screen and (min-width: $xs-screen) {
        font-size: $regular;
      }
    }

    a {
      color: $white;
      text-decoration: underline;
      font-size: $small;
      @media screen and (min-width: $xs-screen) {
        font-size: $regular;
      }
    }

    .informations {
      a {
        display: block;
        font-family: "Museo-light";
        font-size: $small;
        margin-bottom: $margin-sm;
        text-decoration: none;
        cursor: pointer;
        @media screen and (min-width: $xs-screen) {
          font-size: $regular;
        }
      }
    }

  }

  .copyright {
    background-color: $bg-color;

    >div {
      @include content();
      text-align: center;
      padding: $margin-sm;
      @media screen and (min-width: $lg-screen) {
        padding: $margin-sm $margin-lg;
        text-align: start;
      }

      :first-child {
        text-transform: none;
      }
      
      span {
        font-size: 12px;
        text-transform: uppercase;
        font-family: 'MuseoSans';
        line-height: $small;

        @media screen and (min-width: $xs-screen) {
          font-size: $small;
        }
      }
    }
  }
}