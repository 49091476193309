@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.orders {
  .content {
    @include content();

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-sm;

      h1 {
        margin-bottom: 0;
      }
    }


    .filters {
      display: flex;
      margin-bottom: $margin-md;

      .search { 
        display: flex;
        justify-content: flex-end;
        margin-bottom: $margin-xs;
      }

      .field {
        margin-right: $margin-md;
        @media screen and (min-width: 800px) {
          margin-right: $margin-lg;  
        }
      }
    }


    .containerList {
      border-radius: $radius;

      @media screen and (min-width: 1180px) {
        background-color: $white;
        overflow: hidden;

        .list {
          overflow-y: auto;
        }
      }



      .footer {
        padding: $margin-sm 0 $margin-md 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (min-width: 1180px) {
          padding: $margin-sm $margin-lg $margin-md $margin-lg;
        }
      }
    }

  }
}