@import "../../styles/_variables.scss";

.overlay-modal{
  position: fixed;
  z-index: 3000;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  display: flex;
  flex-direction: column;

  z-index: 3001;
  top:$margin-lg;
  left:50%;
  transform: translate(-50%, 0%);
  max-width: 310px;
  width: calc(100% - $margin-xl);
  border-radius: 8px;
  background-color: white;
  max-height: calc(100% - $margin-xl);
  // overflow-y: auto;
  // overflow-x: hidden;

  @media screen and (min-width: $md-screen) {
    top:50%;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .content {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $secondary-color;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    transform: translate(50%,-50%);
    cursor: pointer;
  }

  p{
    margin-top: 0;
  }

  .container-btn{
    display: flex;
    justify-content: center;
    button:first-child{
      margin-right: 5px;
    }
    button:last-child{
      margin-left: 5px;
    }
  }
}
