@import '../../styles/variables';
@import '../../styles/mixins';

.modal {
  width: 100%;
  max-width: 500px;

  h3 {
    font-family: 'MuseoSans-Bold';
    font-size: $regular;
    text-align: center;
    margin-bottom: $margin-md;
  }

  .day {
    margin-bottom: $margin-md;
    .name {
      font-family: 'MuseoSans-Bold';
      font-size: $regular;
      margin-bottom: $margin-sm;
    }

    .timeslots {
      button {
        font-family: 'MuseoSans';
        font-size: $small;
        border: none;
        color: $text-color-light;
        background-color: transparent;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #DBDBDB;
        padding: 10px;
        min-width: 60px;
        margin: 0 $margin-xs $margin-xs 0;
        &:disabled {
          opacity: 0.3;
          background: $grey;
        }
      }
      button:not(:disabled).selected {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $white;
        box-shadow: inset 0 0 0 1px $secondary-color;
      }
    }

  }

  .containerActions {
    display: flex;
    justify-content: center;
  }

}