@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_page-auth.scss';

.registerTypes {
  @include page-auth();

  .container  .form {
    max-width: 500px;

    >p {
      font-size: $h1;
      font-family: "Museo-light";
      color: $secondary-color;
      margin-bottom: $margin-md;
    }

    h1, p {
      text-align: center;
    }

    .types {
      button {
        display: block;
        width: 220px;
        height: 74px;
        margin: 0 auto $margin-md auto;
      }
    }

    .toSignIn {
      display: block;
      width: 240px;
      margin: 0 auto;
      text-align: center;
      color: $secondary-color;
      text-decoration: underline;
    }

  }
}