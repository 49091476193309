@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.detailProduct {

  .banner {
    background-color: #007F92;
    padding: $margin-md;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: $md-screen) {
        flex-direction: row;
      }

      img, p {
        margin: 0 $margin-md;
      }

      img {
        margin-bottom: $margin-lg;
        @media screen and (min-width: $md-screen) {
          margin-bottom: 0;
        }
      }
      
      p {
        font-family: 'MuseoSans';
        color: $white;
        text-align: center;
        max-width: 330px;
      }
    }
  }

  .content {
    @include content();

    .header {
      margin-bottom: $margin-md;
      margin-top: $margin-md;
      @media screen and (min-width: $md-screen) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
      }
    }

    h1, h4 {
      font-family: 'MuseoSans-Bold';
      color: $text-color;
    }    


    .detail {
      display: grid;
      gap: $margin-md;
      grid-template-columns: 1fr;

      @media screen and (min-width: $md-screen) {
        grid-template-columns: 1fr 1fr;
      }


      section {
        h4 {
          margin-bottom: $margin-sm;
        }

        >div {
          background-color: $white;
          border-radius: $radius;
          padding: $margin-sm;
          @media screen and (min-width: $md-screen) {
            padding: $margin-md;
          }

          p {
            span {
              font-family: 'MuseoSans-Bold';
            }
          }

        }

        .discount {
          display: inline-block;
          border: solid 1px $warn;
          padding: $margin-xs $margin-sm;
          margin-bottom: $margin-md;
          p {
            font-family: 'MuseoSans-Bold';
            color: $warn;
            margin: 0;
          }
        }

        .price {
          display: inline-block;
          background-color: $third-color;
          padding: $margin-md;
          text-align: center;
          margin-top: $margin-sm;
          p {
            margin-bottom: 0;
            text-transform: uppercase;
          }
          span {
            font-size: 30px;
            line-height: 42px;
          }
        }

        .franco {
          margin-top: $margin-sm;
        }

      }
    }

  }
}