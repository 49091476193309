@import '../../styles/mixins';
@import '../../styles/variables';

.providers {
  background-color: $bg-color;
  height: 100%;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .header {
      .filters {
        margin-bottom: $margin-sm;
        @media screen and (min-width: $sm-screen) {
          display: flex;
        }
      }
    }


    .containerList {
      flex: 1;
      border-radius: $radius;
      background-color: $white;
      overflow: hidden;
      .list {
        overflow-y: auto;
        height: 100%;
      }
    }
  }


}