@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.bazar {
  background-color: $white;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: $margin-lg;

    @media screen and (max-width: $sm-screen) {
      padding: $margin-lg;
    }

    h1 {
      margin: 0;
    }

    .planContainer {
      text-align: center;
      margin-top: $margin-md;
    }

    h2 {
      @include h1();
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;

      @media screen and (max-width: $xs-screen) {
        text-transform: capitalize;
      }
    }

    a {
      display: inline-block;
      font-family: 'Museo';
      text-decoration: underline;
    }

    .cardsContainer {
      @include content();
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $margin-lg;

      @media screen and (max-width: $sm-screen) {
        flex-direction: column;
      }
    }

    .card, .aloneCard {
      width: 305px;
      background-color: #F5F5F7;
      border-radius: 16px;

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: $margin-md;
        padding: $margin-md;
      }

      h3 {
        @include h2();
      }

      p {
        font-family: 'Museo';
      }
    }

    .card {
      height: 440px;
    }

    .aloneCard {
      height: 475px;

      span {
        display: inline-block;
        margin-bottom: $margin-sm;
      }
    }

  }
}