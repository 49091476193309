
@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label{
    margin-right: 10px;
  }
}

@mixin label-secondary {
  font-family: "Museo", sans-serif;
  font-weight: normal;
  font-size: $regular;
  line-height: 17px; 
  color: $text-color-light;
  white-space: nowrap;
  // padding-left: 13px;
}

@mixin label {
  position: relative;
  font-family: "Museo-Bold", sans-serif;
  font-weight: normal;
  font-size: $regular;
  line-height: 17px; 
  color: $text-color;
  white-space: nowrap;
  // padding-left: 13px;
  sup {
    top: -2px;
    position: absolute;
  }
}

@mixin text {
  font-weight: 400;
  font-size: $regular;
  line-height: 17px;
  color: $text-color;
}

@mixin input {
    display: block;
    border: none;
    outline: none;
    border-radius: 0px;
    width: 100%;
    font-family: "Museo", sans-serif;
    font-size: $regular;
    // min-width: 0px;
    box-sizing: border-box;
    border: solid 1px $border-grey;
    color: $text-color;
    border-radius: 4px;
    padding: 11px;
    line-height: 21px;
    background-color: $white;

    // @media screen and (min-width: $sm-screen) {
    //   min-width: 220px;
    // }

    &::placeholder {
      font-family: "Museo", sans-serif;
      color: $border-grey;
    }
}

@mixin tagform {
  @include tag;
}

@mixin primary {
  .input {
    @include input;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: $radius;
  }
}

@mixin secondary {
  .input {
    @include input;
    border: solid 1px $white;
    font-size: $regular;
    line-height: 17px;
    color: $text-color;
  }
}

@mixin login {
  .input {
    // @include input;
    // padding: 10px 0;
    // font-size: $regular;
    // line-height: 17px;
  }
};