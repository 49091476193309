@import '../../../styles/mixins';
@import '../../../styles/variables';

.banner {
  background: $gradient;
  display: flex;
  justify-content: center;

  img {
    margin: $margin-xl;
  }

  @media screen and (max-width: $md-screen) {
    img {
      margin: $margin-lg;
    }
  }

  @media screen and (max-width: $md-screen) {
    img {
      margin: $margin-md;
    }
  }

  @media screen and (max-width: $sm-screen) {
    background: none;
    background-color: $primary-color;

    .disappearingImage {
      display: none;
    }
  }

}