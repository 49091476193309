$h1: 24px;
$h2: 20px;
$medium: 18px;
$regular: 16px;
$small: 14px;


$bg-color: #F3F7F8;
$primary-color: #5AC5F2;
$secondary-color: #007d92;
$third-color: #F4C922;
$gradient: linear-gradient(180deg, #007D92 0%, #5AC5F2 100%);

$text-color: #202D2F;
$text-color-light: #636363;

$black: #202D2F;
$grey: #DBDBDB;
$white: #FFFFFF;

$border-grey: #DBDBDB;

$success: #14AE5C;
$warn: #EC5E52;

$lg-screen: 1200px;
$md-screen: 1024px;
$sm-screen: 768px;
$xs-screen: 580px;

$margin-xl: 50px;
$margin-lg: 30px;
$margin-md: 20px;
$margin-sm: 10px;
$margin-xs: 5px;

$radius: 10px;

$header-height: 77px;


@font-face {
	font-family: "MuseoSans";
	src: url("../assets/fonts/museoSans/museosans-500.otf") format("opentype");
}

@font-face {
	font-family: "MuseoSans-Bold";
	src: url("../assets/fonts/museoSans/MuseoSans-700.otf") format("opentype");
}

@font-face {
	font-family: "Museo";
	src: url("../assets/fonts/museo/Museo500-Regular.otf") format("opentype");
}

@font-face {
	font-family: "Museo-light";
	src: url("../assets/fonts/museo/museo-300.otf") format("opentype");
}

@font-face {
	font-family: "Museo-Bold";
	font-weight: normal;
	src: url("../assets/fonts/museo/Museo700.otf") format("opentype");
}

@font-face {
	font-family: "Museo-ExtaBold";
	src: url("../assets/fonts/museo/Museo900.otf") format("opentype");
}