@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.shoppingCart {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    @include content();
    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
      margin-bottom: $margin-md;

      @media screen and (min-width: $sm-screen) {
        display: flex;
        align-items: center;
      }

      .titleAndActions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 $margin-md $margin-sm 0;
  
        @media screen and (min-width: $sm-screen) {
          display: block;
          margin: 0 $margin-md 0 0;
        }

        h1 {
          margin: 0;
        }

        .actions {
          display: flex;
          @media screen and (min-width: $sm-screen) {
            display: none;
          }
        }

      }



      .detail {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (min-width: $xs-screen) {
          flex-direction: row;
          align-items: center;
        }
  
        >span {
          display: inline-block;
          background-color: $white;
          margin-bottom: $margin-sm;
          padding: $margin-xs $margin-sm;
          border-radius: 5px;
          @media screen and (min-width: $xs-screen) {
            margin-bottom: 0;
            margin-right: $margin-md;
          }

          p {
            font-family: 'Museo-light';
            margin: 0;
            span {
              font-family: 'MuseoSans';
            }
          }

          &.save p span {
            color: $success;
          }

        }

        .actions {
          display: none;
          @media screen and (min-width: $sm-screen) {
            display: block;
            margin-left: auto;
          }
        }

      }
    }

    .filters {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: $margin-sm;


      @media screen and (min-width: 590px) {
        display: flex;
        grid-template-columns: inherit;
        grid-template-rows: inherit;
      }

      .containerButtonFilter { 
        grid-area: 1 / 1 / 2 / 2;
        margin-bottom: $margin-xs;
        @media screen and (min-width: 590px) {
          grid-area: inherit;
          margin-bottom: 0;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: inherit;
          .icon {
            position: relative;
            top:2px;
            color: $secondary-color;
            margin-left: 5px;
          }
        }

      }

      .search {
        display: flex;
        align-items: center;
        margin: 0 0 $margin-xs 0;
        // grid-area: 1 / 2 / 2 / 3;
        // margin-bottom: $margin-xs;
        @media screen and (min-width: 590px) {
          margin: 0 0 $margin-xs 0;
        }
      }


      .selectFilters {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-area: 2 / 1 / 3 / 3;
        max-width: 400px;

        @media screen and (min-width: 590px) {
          display: flex;
          grid-area: inherit;
          flex-direction: row;
          max-width: inherit;
        }
    
        .field {
          margin-right: $margin-md;
          @media screen and (min-width: 590px) {
            margin-right: $margin-lg;  
          }
        }
      }
  
    }

  }
}