@import '../../styles/mixins';
@import '../../styles/variables';

.pgc {
  background-color: $white;

  .content {
    @include content();
    padding: $margin-lg;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    gap: $margin-lg;
  }

  h1 {
    padding: 0 $margin-xs;
    font-size: $medium;

    @media screen and (min-width: 430px) {
      padding: 0;
    }

    @media screen and (min-width: 670px) {
      @include h1();
    }
  }

  .blocsContainer {
    display: flex;
    flex-direction: column;
    gap: 26px;

    @media screen and (min-width: 670px) {
      display: flex;
      // flex-direction: column-reverse;
      gap: 26px;
    }
  }

  .planContainer {
    display: flex;
    justify-content: space-between;
    
    h2 {
      font-size: $h1;
      color: $black;

      @media screen and (min-height: 430px) {
        font-size: $h2;
        text-transform: uppercase;
      }
    }

    a {
      font-family: 'Museo';
      text-decoration: underline;
    }

    @media screen and (min-width: 430px) {
      display: block;
      align-items: center;

      h2 {
        margin: 0;
      }

      a {
        display: inline-block;
        margin: 0 0 $margin-md;
      }
    }
  }

  .bloc2 {
    @media screen and (min-width: 1340px) {
      padding: 0 100px;
    }
  }

  .cardsHall4, .cardsHall3, .cardsHall2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: $margin-md;
  }

  .card4, .card3, .card2 {
    width: 305px;
    height: 573px;
    background-color: #F5F5F7;
    border-radius: 16px;
    padding: 0 0 $margin-md;

    h3, p {
      padding: 0 $margin-md;
    }

    img {
      @media screen and (min-width: 670px) {
        display: inline;
      }
    }

    h3 {
      @include h2();
      margin: $margin-md 0 $margin-md;
    }

    p {
      font-family: 'Museo';
      margin: 0 0 $margin-xs;
    }
  }

  .card3 {
    height: 425px;
  }

  .card2 {
    height: 429px;
  }
}