@import '../../styles/variables';
@import '../../styles/mixins';

.presenceValidated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $margin-md;
    padding: 0 $margin-xs;

    @media screen and (min-width: $xs-screen) {
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    h3 {
      font-family: 'MuseoSans-Bold';
      font-size: $regular;
      flex: 1;
      min-width: 100%;
      margin-bottom: $margin-sm;


      @media screen and (min-width: $xs-screen) {
        margin: 0;
        min-width: auto;
      }


      span {
        font-family: 'Museo-Light';
        color: $text-color-light;
      }
    }

    .scanner {
      margin-right: $margin-sm;
      @media screen and (min-width: $xs-screen) {
        margin-left: auto;
      }
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 $margin-md;
      }
      svg {
        margin-right: $margin-sm;
      }
    }

    >button {
      background-color: $white;
      border: 1px solid var(--gris, #A4B7BB);
      padding: 15px;
      width: 50px;
      height: 50px;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
  
  }

  .users {
    max-height: 400px;
    overflow-y: auto;
    padding: 0 $margin-xs;
    .user {
      .name {
        font-family: 'MuseoSans-Bold';
        font-size: $regular;
      }
      .detail {
        @media screen and (min-width: $sm-screen) {
          display: flex;
          justify-content: space-between;
        }

        p {
          font-family: 'Museo-Light';
          margin: 0 0 $margin-xs 0;
          @media screen and (min-width: $sm-screen) {
            margin: 0;
          }

          span {
            color: $text-color-light;
          }
        }
      }

      &:not(:last-child) {
        padding-bottom: $margin-sm;
        margin-bottom: $margin-sm;
        border-bottom: solid 1px $grey;
      }

      .presence {
        &.validated {
          color: #008767;
        }
        &:not(.validated) {
          opacity: 0.2;
          svg {
            position: relative;
            top: 3px;
          }
        }
        svg {
          position: relative;
          top: 2px;
          margin-left: 3px;
        }
      }


    }
  }
  

}